nav {
  display: flex;
  flex-direction: column;

  padding-bottom: 1rem;
}

.nav-top-container {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.site-title-container {
  margin-left: 3rem;
  padding: 0.4rem 1rem;
  outline-style: dotted;
}

.site-title {
  color: var(--inca-gold);
}

.subtitle {
  display: flex;
  width: fit-content;
  padding: 0.2rem;

  margin-left: auto;

  color: var(--inca-gold);
  font-size: 0.8em;
}

/* --------- create ------------ */
.create-link,
.signup-link,
.login-link,
.logout-link {
  display: flex;
  margin-right: 3rem;
  padding: 0.8rem;

  color: var(--inca-gold);

  border: 2px solid var(--inca-gold);
}
.create-text,
.signup-text,
.login-text,
.logout-text {
  line-height: 1.2;
}

.create-link:hover,
.signup-link:hover,
.login-link:hover,
.logout-link:hover {
  cursor: pointer;

  box-shadow: 0 0 0.4rem var(--inca-gold);
}
.logout-link {
  background: none;
  font-size: 1rem;
}
/* --------- bottom-container ------------ */
.nav-bottom-container {
  display: flex;
  justify-content: space-between;

  padding: 0.3rem 2rem;
  align-items: center;

  background: var(--inca-gold);
}

/* --------- search ------------ */
.search-field {
  align-self: flex-end;

  border: none;
  border-radius: 0%;

  width: 14rem;
  height: 2rem;

  outline: thin solid var(--black-pearl);
  font-size: 1.1rem;
  padding: 8px;
}

.search-field:focus {
  outline: 0.2rem solid var(--black-pearl);
}

/* --------------------- black-pearl ------------------ */
.nav-black-pearl .nav-top-container {
  background: var(--black-pearl);
}
.nav-black-pearl .site-title:hover {
  color: var(--noodles);
}

/* --------------------- noodles --------------------- */
.nav-noodles .nav-top-container {
  background: var(--noodles);
}
.nav-noodles .site-title:hover {
  color: var(--carbon);
}
