/* -------------------- Tag --------------------- */
.tag-container {
  margin: 10px 0 0 0;
}

.tag-list {
  padding: 10px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
  font-size: 1.2rem;
}

.tag-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tag-option input {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
/* -------------------- Rating --------------------- */

.rating-container {
  display: flex;
  font-size: 20px;
}
.rating-fieldset {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.rating-container div {
  margin-top: 12px;
  margin-left: 12px;
}
.rating-holder {
  font-size: 20px;
  display: flex;
  flex-direction: 20%;
  justify-content: space-between;

  margin: 4px;
}

.rating-option {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;

  border: 1px solid var(--inca-gold);
  border-radius: 50%;

  text-align: center;

  font-size: 1rem;

  cursor: pointer;
}

.not-selected:hover {
  box-shadow: 0 0 0.4rem var(--inca-gold);
  background: var(--inca-gold);
  color: var(--noodles);
}

.rating {
  display: none;
}
.selected {
  background: var(--inca-gold);
  color: var(--noodles);
}
