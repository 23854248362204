.recipe-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 800px;
  margin: auto;
}
.recipe-list .card {
  border: 1px solid var(--inca-gold);

  position: relative;
  padding: 20px;

  /* border-radius: 4px; */
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease;
  height: 250px;
}
.recipe-list .card:hover {
  transform: rotate(3deg);
}
.card-title {
  font-family: "Cinzel", serif;
  margin-bottom: 6px;
  padding: 5px;
  text-align: center;
  height: 30%;
  align-items: center;
  border-bottom: 0.2rem dotted var(--inca-gold);

  overflow-wrap: break-word;
}
.card-method {
  font-size: 0.9em;
  text-align: center;
}
.card-time {
  font-size: 1rem;
  margin: 20px 10px;
  line-height: 1.5em;
  text-align: right;
}
.card-link {
  color: var(--noodles);
  text-decoration: none;
  display: block;
  background: var(--inca-gold);
  font-size: 0.9em;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}
.delete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  filter: invert(60%);
}
