/* brings styles to bottom of page: min-height */
.app {
  min-height: 100%;
}
.app-dark {
  background: var(--black-pearl);
}
.app-light {
  background: var(--noodles);
}
.app .centering-app {
  display: flex;
  justify-content: center;
}
.app .side-by-side {
  display: flex;
}
