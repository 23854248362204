@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Pacifico&display=swap");

:root {
  --black-pearl: #152429;
  --noodles: #ffe0b1;

  --inca-gold: #c08224;
  --carbon: #333333ff;

  --pink-cherry: rgb(109, 46, 57);
  /* --stay-the-night: #324561; */
}

/* base styles */
html,
body,
#root {
  height: 100%;
}
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.4em;

  color: var(--carbon);
}
h1,
h2,
h3,
p {
  margin: 0;
}
input,
textarea {
  color: var(--carbon);
  padding: 6px;
  font-size: 0.8em;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
legend {
  font-size: 1.4rem;
}
fieldset {
  margin-top: 1rem;
  border-radius: 1rem;
}

li {
  list-style: none;
}

.site-title {
  font-family: "Cinzel", serif;
}
.page-title {
  text-align: center;
  margin: 40px auto;
  font-family: "Cinzel", serif;
}
.loading,
.error {
  text-align: center;
  margin: 40px auto;
}

/* home */
.home {
  max-width: 800px;

  padding: 8px 40px;
  margin-top: 2rem;
}

.card,
.recipe {
  border: 1px solid var(--inca-gold);
  border-radius: 4px;
}

/* RecipeList, Recipe: Dark Mode */
.card-dark,
.empty-list-dark {
  color: var(--noodles);
}
.card-dark fieldset {
  border: 1px solid var(--noodles);
}

/* RecipeList, Recipe: Light Mode */
.card-light,
.empty-list-light {
  color: var(--black-pearl);
}
.card-light fieldset {
  border: 1px solid var(--black-pearl);
}
