.recipe {
  max-width: 800px;
  margin: 30px auto;
  text-align: center;

  padding: 40px;
  box-sizing: border-box;
  position: relative;

  /* border-radius: 4px; */
}
.recipe .page-title {
  margin: 10px 30px;
}
.recipe ul {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
  margin-top: 0;
}
.recipe li {
  list-style-type: none;
  margin-right: 10px;

  vertical-align: text-bottom;
}
.recipe li::after {
  content: ",";
}
.recipe li:last-child::after {
  content: ".";
}
.recipe .method {
  text-align: left;
  line-height: 1.5em;
}

.recipe a {
  color: var(--noodles);
  text-decoration: none;
  display: block;
  background: var(--inca-gold);
  font-size: 0.9em;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}
