.theme-selector {
  display: flex;

  max-height: 24px;
  max-width: 106px;

  padding: 8px;
}

.theme-buttons div {
  display: inline-block;
  margin-left: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  border-radius: 50% / 25%;

  border: 2px solid var(--carbon);
}
.mode-toggle {
  align-items: center;
}
.mode-toggle img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
